import React from "react";
import { DashboardPage } from '../pages'
import { AccountPage, AccountDomainPage, AccountsSourceOverridesPage, ConnectionsPage, ConnectionSemaphoresPage, CustomConnectionsPage, InboundFilesPage, InboundEmailAddressesPage, InboundEmailMessagesPage, ExtractorRateLimitPage, ExtractorSemaphoresPage, ExtractorTimeZoneSpecificationsPage, ExtractorPage, CollectionMissionStateTransitionPage, ControlMissionPage } from "../pages/account-pages";
import { UserPage, UserDashboardPage, UserReportPage, InstalledApplicationPage, ReportChangeHistoriesPage, ReportColumnsPage, ReportColumnFieldsPage, ReportColumnFiltersPage, ReportRunSchedulesPage, ReportViewsPage, AppletsPage, SourceReportsPage, ReportFilterPage, ReportDataSourceFilterPage } from '../pages/user-pages'
import { PrivateRoute } from "./auth-gaurd/PrivateRoute";
import {StaqEventsPage, StaqContactPage,CustomerFaviconPage,CustomerLogoPage,DatabaseEginePage, DataEginePage,DatabaseEgineJobPage, ApplicationsPage, ExtractionEnginesPage,PlatformPage,ExtractionSchedulingRecipesPage, ExtractionSchedulingRecipesChangesPage,InvocationSchedulesPage,InvocationHealthSchedulesPage,SchemasPage,ScopesPage,ScopeSchemaChangesPage,FieldsPage, RolesPage,FieldClassificationPage, ScopeFieldSchemaChangesPage, TagLocationCategoriesPage, TagLocationPage, TagMatchersPage, FeatureFlagsPage, EmailLayoutsPage, 
  EmailTemplatesPage, ProtectedOperationsPage, PlanCategoriesPage,SubscriptionsPage, SubscriptionCategoryPage, CategoryPage, ConfigurationsPage } from "../pages/individual-pages/";
import { CollectionMissionTaskPage } from "../pages/account-pages/CollectionMissionTaskPage";






export function MapRoute() {  
  return (
    
        <div className="compLoader">
         
          <PrivateRoute exact path='/' component={DashboardPage} />

          {/* Account Routes Mapping */}
          <PrivateRoute path="/account" component={AccountPage} />
          <PrivateRoute path="/account-domain" component={AccountDomainPage} />
          <PrivateRoute path="/account-source-override" component={AccountsSourceOverridesPage} />
          <PrivateRoute path="/connection" component={ConnectionsPage} />
          <PrivateRoute path="/extractor" component={ExtractorPage} />
          <PrivateRoute path="/extractor-rate-limit" component={ExtractorRateLimitPage} />
          <PrivateRoute path="/extractor-time-zone-specification" component={ExtractorTimeZoneSpecificationsPage} />
          <PrivateRoute path="/extractor-semaphore" component={ExtractorSemaphoresPage} />
          <PrivateRoute path="/custom-connection" component={CustomConnectionsPage} />
          <PrivateRoute path="/connection-semaphore" component={ConnectionSemaphoresPage} />
          <PrivateRoute path="/inbound-file" component={InboundFilesPage} />
          <PrivateRoute path="/inbound-email-address" component={InboundEmailAddressesPage} />
          <PrivateRoute path="/inbound-email-message" component={InboundEmailMessagesPage} />

          {/* User Routes Mapping */}
          <PrivateRoute path="/user" component={UserPage} />
          <PrivateRoute path="/dashboard" component={UserDashboardPage} />
          <PrivateRoute path="/report" component={UserReportPage} />
          <PrivateRoute path="/installed-report-application" component={InstalledApplicationPage} />
          <PrivateRoute path="/report-change-history" component={ReportChangeHistoriesPage} />
          <PrivateRoute path="/report-column" component={ReportColumnsPage} />
          <PrivateRoute path="/report-column-field" component={ReportColumnFieldsPage} />
          <PrivateRoute path="/report-column-filter" component={ReportColumnFiltersPage} />
          <PrivateRoute path="/report-data-source-filter" component={ReportDataSourceFilterPage} />
          <PrivateRoute path="/report-filter" component={ReportFilterPage} />
          <PrivateRoute path="/report-run-schedule" component={ReportRunSchedulesPage} />
          <PrivateRoute path="/report-view" component={ReportViewsPage} />
          <PrivateRoute path="/applet" component={AppletsPage} />
          <PrivateRoute path="/source-report" component={SourceReportsPage} />


          {/* Individual Routes Mapping */}
          <PrivateRoute path="/application" component={ApplicationsPage} />
          <PrivateRoute path="/invocation-schedule" component={InvocationSchedulesPage} />
          <PrivateRoute path="/invocation-health-schedule" component={InvocationHealthSchedulesPage} />
          <PrivateRoute path="/role" component={RolesPage} />
          <PrivateRoute path="/platform" component={PlatformPage} />
          <PrivateRoute path="/category" component={CategoryPage} />
          <PrivateRoute path="/admin" component={StaqContactPage} />
          <PrivateRoute path="/customer-favicon" component={CustomerFaviconPage} />
          <PrivateRoute path="/customer-logo" component={CustomerLogoPage} />
          <PrivateRoute path="/database-engine" component={DatabaseEginePage} />
          <PrivateRoute path="/data-engine" component={DataEginePage} />
          <PrivateRoute path="/data-engine-job" component={DatabaseEgineJobPage} />
          <PrivateRoute path="/extraction-engine" component={ExtractionEnginesPage} />
          <PrivateRoute path="/extraction-scheduling-recipe-change" component={ExtractionSchedulingRecipesChangesPage} />
          <PrivateRoute path="/extraction-scheduling-recipe" component={ExtractionSchedulingRecipesPage} />
          <PrivateRoute path="/schema" component={SchemasPage} />
          <PrivateRoute path="/scope" component={ScopesPage} />
          <PrivateRoute path="/subscription" component={SubscriptionsPage} />
          <PrivateRoute path="/subscription-category" component={SubscriptionCategoryPage} />
          <PrivateRoute path="/field" component={FieldsPage} />
          <PrivateRoute path="/scope-schema-change" component={ScopeSchemaChangesPage} />
          <PrivateRoute path="/field-classification" component={FieldClassificationPage} />
          <PrivateRoute path="/tag-location-category" component={TagLocationCategoriesPage} />
          <PrivateRoute path="/tag-location" component={TagLocationPage} />
          <PrivateRoute path="/staq-event" component={StaqEventsPage} />
          <PrivateRoute path="/tag-matcher" component={TagMatchersPage} />
          <PrivateRoute path="/email-template" component={EmailTemplatesPage} />
          <PrivateRoute path="/protected-operation" component={ProtectedOperationsPage} />
          <PrivateRoute path="/scope-field-schema-change" component={ScopeFieldSchemaChangesPage} />
          <PrivateRoute path="/feature-flag" component={FeatureFlagsPage} />
          <PrivateRoute path="/email-layout" component={EmailLayoutsPage} />
          <PrivateRoute path="/plan-category" component={PlanCategoriesPage} />
          <PrivateRoute path="/configuration" component={ConfigurationsPage} />
          <PrivateRoute path='/collection-mission-state-transition' component={CollectionMissionStateTransitionPage} />
          <PrivateRoute path='/collection-mission-task' component={CollectionMissionTaskPage} />
          <PrivateRoute path='/control_mission' component={ControlMissionPage} />
          
          
        </div> 
  )
}
 