import React, { Component, Fragment, createRef } from 'react'
import { InstalledApplicationModel, EditInstalledApplicationModel, InvocationSchedulesModel, InvocationSchedulesHealthModel } from './models'
import { DropdownItemModel } from '../../../../shared/models'
import { InstalledApplicationService } from './services/installed.application.service';
import { CommonService } from '../../../../shared/services/common.service';
import { HttpResponse } from '../../../../core';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { Link, NavLink, Route } from "react-router-dom";
import { Loader } from '../../../../shared/loaders';
import { Editor } from '../../../../shared';
import { InstalledApplicationTabs } from './InstalledApplicationTabs'
import { ValidateYaml } from '../../../../shared/helpers/yaml.helper';
import { NotFoundResponseArea } from '../../../../shared/enums';
import { ReportFormPopup } from '..';
import { Modal } from 'react-bootstrap';

interface IProps { 
    handleClose: Function,
    id?:number;
    isNew?:boolean;    
    updateInstalledApplication:Function
    location?:string;
    history: Function
} 

interface IState {
    installedApplication?: InstalledApplicationModel,
    editInstalledApplication?: EditInstalledApplicationModel,
    id?: number;
    name?: string;
    report_id?: number; 
    application_id?: number;
    enabled?: boolean; 
    run_on_report_change?: boolean;
    billable?: boolean;
    invocation_schedules?: Array<InvocationSchedulesModel>;
    invocation_schedules_health?: Array<InvocationSchedulesHealthModel>;
    revealable_options?: string;
    options?: string;

    reportList?: DropdownItemModel[],
    reportName?: string,
    applicationList?: DropdownItemModel[],
    applicationName?: string,

    nameError?: string,
    reportError?: string,
    applicationError?: string,
    isShowtext?: string;
    isSave?: string;
    isSaving?: boolean;

    invocationScheduleName?: string;
    invocationScheduleId?: number;
    invocationScheduleEditedId?: number;

    invocationSchedulesError?: string;

    invocationScheduleHealthName?: string;
    invocationScheduleHealthId?: number;
    invocationScheduleHealthEditedId?: number;

    invocationSchedulesHealthError?: string;

    revealableOptionsError?: string;

    showReportForm?: boolean;
    isNew?:boolean;
    isChildUpdate?:boolean;
}
export class InstalledApplicationsFormPopup extends Component<IProps, IState> {
    //declaring service type
    private service: InstalledApplicationService;
    private commonService: CommonService;
    private nameRef: any;

    private roleNetworkCall: any;
    private NetworkCall: any;

    constructor(props:IProps) {
        super(props)
        this.service = new InstalledApplicationService();
        this.commonService = new CommonService();
        this.state = this.initialState;
        this.editorhandleChangeReveableOptions = this.editorhandleChangeReveableOptions.bind(this);
        this.editorhandleChangeOptions = this.editorhandleChangeOptions.bind(this);
        this.nameRef = createRef();
        this.updateReport = this.updateReport.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    initialState: Partial<IState> = {
        installedApplication: {
            id: 0,
            name: "",
            application_id: 0,
            report_id: 0,
            billable: true,
            run_on_report_change: false,
            enabled: false,
            invocation_schedules: [],
            invocation_schedules_health: [],
            options: "",
            revealable_options: ""
        },
        id: 0,
        name: "",
        application_id: 0,
        report_id: 0,
        billable: true,
        run_on_report_change: false,
        enabled: false,
        invocation_schedules: [],
        invocation_schedules_health: [],
        options: "",
        revealable_options: "",
        reportList: [],
        reportName: "",
        applicationName: "",
        applicationList: [],
        nameError: "",
        reportError: "",
        applicationError: "",
        isShowtext: "New",
        isSave: "",
        isSaving: false,
        invocationScheduleName: "",
        invocationScheduleId: 0,
        invocationScheduleEditedId: 0,
        invocationSchedulesError: "",
        invocationScheduleHealthName: "",
        invocationScheduleHealthId: 0,
        invocationScheduleHealthEditedId: 0,
        invocationSchedulesHealthError: "",
        revealableOptionsError: "",

        showReportForm:false,
        isNew:false,
        isChildUpdate:false
    }
    
    handleClose = event => {
        event.preventDefault();
        this.props.handleClose();        
    }

    componentDidMount() {
        if (!this.props.isNew) {
            this.setState({ id: this.props.id, isShowtext: "Edit" }, () => {
                this.loadData();
            });
        }
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        // Use event.composedPath() if available, otherwise fallback to event.path or empty array
        const path = event.composedPath ? event.composedPath() : (event.path || []);

        // Handle the reportName/report
        if (path[0] && path[0].id !== 'reportName' && path[0].id !== 'report' &&
            (event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight)) {
            this.setState({
                reportList: []
            });
        }

        // Handle the applicationName/application
        if (path[0] && path[0].id !== 'applicationName' && path[0].id !== 'application' &&
            (event.clientX < event.target.clientWidth || event.clientY < event.target.clientHeight)) {
            this.setState({
                applicationList: []
            });
        }
    };


    updateReport(isChildUpdate,name,id)
    {
      this.setState({isChildUpdate:isChildUpdate,reportName:name,report_id:id})
    }

    handleReportClose = () => {
        this.setState({ showReportForm: false }, () => {
        });
    }

    handleShowReport = () => {        
        this.setState({ showReportForm: true, isNew:true }, () => {

        });
    }

    handleShowEditReport = () => {        
        this.setState({ showReportForm: true, isNew:false }, () => {
 
        });
    }

    loadData() {
        this.service.editInstalledApplication(this.props.id)
            .then((res: HttpResponse<EditInstalledApplicationModel>) => {
                if (res && res.result) {
                    let invocationSchedules = Array<InvocationSchedulesModel>();
                    let invocationSchedulesHealth = Array<InvocationSchedulesHealthModel>();
                    res.result?.invocation_schedules?.forEach(p => {
                        invocationSchedules.push({ id: p.id, run_at_time: p.run_at_time, installed_report_application_id: p.installed_report_application_id, tempId: p.id })
                    })
                     res.result?.invocation_schedules_health?.forEach(p => {
                        invocationSchedulesHealth.push({ id: p.id, report_health: p.report_health, installed_report_application_id: p.installed_report_application_id, tempId: p.id })
                    })
                    this.setState({
                        billable: res.result?.billable,
                        applicationName: res.result?.application.text,
                        application_id: res.result?.application.value,
                        reportName: res.result?.report.text,
                        report_id: res.result?.report.value,
                        name: res.result?.name,
                        id: res.result?.id,
                        run_on_report_change: res.result?.run_on_report_change,
                        enabled: res.result?.enabled,
                        invocation_schedules: invocationSchedules,
                        invocation_schedules_health: invocationSchedulesHealth,
                        options: res.result?.options == null ? "" : res.result?.options,
                        revealable_options: res.result?.revealable_options == null ? "" : res.result?.revealable_options
                    }, () => { });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex,NotFoundResponseArea.InstalledApplications,this.props) 
            });
    }

    editorhandleChangeReveableOptions(data) {
        this.setState({
            revealable_options: data
        }, () => {
            // this.validateRevealableoptions();
        })
    }
    editorhandleChangeOptions(data) {
        this.setState({
            options: data
        })
    }

    handleChange = (event: any) => {
        const installedAppName = event.target.name;
        if (installedAppName == "applicationName") {
            this.onApplicationChange(event);
        }
        if (installedAppName == "reportName") {
            this.onReportChange(event);
        }
        else {
            const isCheckbox = event.target.type === "checkbox";
            this.setState({
                [event.target.name]: isCheckbox
                    ? event.target.checked
                    : event.target.value
            }, () => {
                // this.validate();
                if (installedAppName == "invocationScheduleName") {
                    this.validateInvocationSchedule();
                }
                if (installedAppName == "invocationScheduleHealthName") {
                    this.validateInvocationScheduleHealth();
                }
                // this.validateInvocationSchedule();
            })
        }
        if (this.nameRef.current.value) {
            this.setState({ nameError: "" });
        }

    }


    validate = () => {
        let nameError = "";
        let applicationError = "";
        let reportError = "";
        if (!this.nameRef.current.value) {
            nameError = "Name can't be blank";
        }

        if (!this.state.applicationName) {
            applicationError = "Application can't be blank";
        }
        if (!this.state.reportName) {
            reportError = "Report can't be blank";
        }

        if (nameError || applicationError || reportError) {
            this.setState({ nameError: nameError, applicationError: applicationError, reportError: reportError })
            return false;
        }

        if (!nameError || !applicationError || !reportError) {
            nameError = "";
            applicationError = "";
            reportError = "";
            this.setState({ nameError: nameError, applicationError: applicationError, reportError: reportError })
            return true;
        }

    }

    validateInvocationSchedule() {
        let invocationSchedulesError = ""
        var pattern = new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$');
        let evalInvocationSchedule = pattern.test(this.state.invocationScheduleName!);
        if (!evalInvocationSchedule && this.state.invocationScheduleName !== "") {
            invocationSchedulesError = 'Please enter correct data format.';
        }

        if (invocationSchedulesError) {
            this.setState({ invocationSchedulesError: invocationSchedulesError })
            return false;
        }
        if (!invocationSchedulesError) {
            invocationSchedulesError = ""
            this.setState({ invocationSchedulesError: invocationSchedulesError })
            return true;
        }
    }

    //****************** Validate Invocation Schedule Report Health *********************//
    validateInvocationScheduleHealth() {
        const { invocationScheduleHealthName, invocation_schedules_health } = this.state;
        let invocationSchedulesHealthError = ""
        if(invocationScheduleHealthName !== ""){
            var pattern = new RegExp('^(100|[1-9][0-9]?)$');
            let evalInvocationScheduleHealth = pattern.test(invocationScheduleHealthName!);
            // Check if the entered health value is already in the list
            const isDuplicate = invocation_schedules_health?.some(
                (schedule) => `${schedule.report_health}` === invocationScheduleHealthName
            );

            if (!evalInvocationScheduleHealth) {
                invocationSchedulesHealthError = 'Please enter correct data format.';
            } else if(isDuplicate) {
                invocationSchedulesHealthError = 'This value already exists in the list. Please enter a unique value.';
            }
        }

        if (invocationSchedulesHealthError) {
            this.setState({ invocationSchedulesHealthError: invocationSchedulesHealthError })
            return false;
        }
        if (!invocationSchedulesHealthError) {
            invocationSchedulesHealthError = ""
            this.setState({ invocationSchedulesHealthError: invocationSchedulesHealthError })
            return true;
        }
    }

    validateRevealableoptions() {
        let isValid = ValidateYaml(this.state.revealable_options);
        if (isValid) {
            this.setState({ revealableOptionsError: "" })
            return true;
        }
        if (!isValid) {
            this.setState({ revealableOptionsError: "Revealable Options is not valid please type valid YAML" })
            return false;
        }
    }



    handleSubmit = event => {
        event.preventDefault();
        if(!this.state.isChildUpdate)
        {
        let invocationSchedules = Array<InvocationSchedulesModel>();
        let invocationSchedulesHealth = Array<InvocationSchedulesHealthModel>();
        this.state.invocation_schedules?.forEach(p => {
            invocationSchedules.push({ id: p.id, run_at_time: p.run_at_time, installed_report_application_id: p.installed_report_application_id })
        })
        this.state.invocation_schedules_health?.forEach(p => {
            invocationSchedulesHealth.push({ id: p.id, report_health: p.report_health, installed_report_application_id: p.installed_report_application_id })
        });
        const isValid = this.validate();
        const isrevealableOptionValid = this.validateRevealableoptions();
        let installedApplicationData: InstalledApplicationModel = {
            billable: this.state.billable,
            report_id: this.state.report_id,
            application_id: this.state.application_id,
            name: this.state.name,
            id: this.state.id,
            run_on_report_change: this.state.run_on_report_change,
            enabled: this.state.enabled,
            invocation_schedules: invocationSchedules,
            invocation_schedules_health: invocationSchedulesHealth,
            options: this.state.options,
            revealable_options: this.state.revealable_options
        };
         
        if (isValid && isrevealableOptionValid) {
            if (installedApplicationData.id === 0) {
                this.postData(installedApplicationData);
            }
            else {
                this.updateData(installedApplicationData)
            }
        }
    }
    }

    postData(installedApplication: InstalledApplicationModel) {
        this.setSavingFlag(true);
        this.service.postInstalledApplication(installedApplication)
            .then((res: HttpResponse<InstalledApplicationModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        // installedApplication: res.result,
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Installed Application successfully created.");
                        if (this.state.isSave === ButtonType.Save) {
                            this.setState(this.initialState);
                            this.props.updateInstalledApplication(true,this.state.name,this.state.id);
                            this.props.handleClose();
                            this.props.history('/installed-report-application/detail/'+ this.state.id);
                        }
                        
                    });

                }
            }, () => {
                this.setSavingFlag(false);
            });;
    }

    updateData(installedApplication: InstalledApplicationModel) {
        this.setSavingFlag(true);
        this.service.updateInstalledApplication(installedApplication)
            .then((res: HttpResponse<InstalledApplicationModel>) => {
                this.setSavingFlag(false);
                if (res && res.result) {
                    this.setState({
                        id: res.result.id
                    }, () => {
                        ShowSuccessMessage("Installed Application successfully updated");
                        if (this.state.isSave === ButtonType.Save) {
                            this.setState(this.initialState);
                            this.props.updateInstalledApplication(true,this.state.name,this.state.id);
                            this.props.handleClose();
                            this.props.history('/installed-report-application/detail/'+ this.state.id);

                        }
                       
                    });

                } 
            }, () => {
                this.setSavingFlag(false);
            });
    }

    handleClick = (event) => {
        this.setState({ isSave: event.target.value },()=>{
            this.props.updateInstalledApplication(true,this.state.name,this.state.id);
        })
    }
    private setSavingFlag(saving: boolean) {
        this.setState({ isSaving: saving });
    }

    //*************** * Reports Start************************* //
    onReportChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.NetworkCall) {
                clearTimeout(this.NetworkCall);
            }
            this.NetworkCall = setTimeout(() => {
                this.getReports(value)
            }, 600)
        }
        this.setState({
            reportName: value,
            reportError: ""
        }, () => {
            // this.validate();
        })
    }

    selectedReport(item) {
        this.setState({
            report_id: item.value,
            reportName: item.text,
            reportList: []
        })
    }

    private getReports(text: string) {
        this.commonService.getReportsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        reportList: res.result
                    });
                }
            });

    }

    renderReport = () => {
        if (this.state.reportList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.reportList?.map((item, index) => (<li id="report" key={index} onClick={() => this.selectedReport(item)}>{item.text}</li>))
                }
            </ul>
        );
    }
    //*************** * Reports End************************* //


    //*************** * Application Start************************* //

    onApplicationChange = (e) => {
        const value = e.target.value;
        if (value.length > 0) {
            if (this.NetworkCall) {
                clearTimeout(this.NetworkCall);
            }
            this.NetworkCall = setTimeout(() => {
                this.getApplications(value)
            }, 600)
        }
        this.setState({
            applicationName: value,
            applicationError: ""
        }, () => {
            // this.validate();
        })
    }

    selectedApplication(item) {
        this.setState({
            application_id: item.value,
            applicationName: item.text,
            applicationList: []
        })
    }

    renderApplication = () => {
        if (this.state.applicationList?.length === 0) {
            return null;
        }
        return (
            <ul className="list-unstyled auto-suggest">
                {
                    this.state.applicationList?.map((item, index) => (<li id="application" key={index} onClick={() => this.selectedApplication(item)}>{item.text}</li>))
                }
            </ul>
        );
    }

    private getApplications(text: string) {
        this.commonService.getApplicationsByName(text)
            .then((res: HttpResponse<DropdownItemModel[]>) => {
                if (res) {
                    this.setState({
                        applicationList: res.result
                    });
                }
            });

    }

    //*************** * Application End************************* //


    createInvocationschedules(id: number) {
        const isValid = this.validateInvocationSchedule();
        if (isValid) {
            id = id + 1
            this.state.invocation_schedules?.push({
                id: 0,
                tempId: id,
                installed_report_application_id: this.state.id,
                run_at_time: this.state.invocationScheduleName
            })

            this.setState({
                invocationScheduleId: id,
                invocationScheduleName: ""
            })
        }

    }
    removeInvocationSchedule(id) {
        let index = this.state.invocation_schedules?.findIndex(p => p.tempId === id)
        if (index! > -1) {
            if (index! > -1) {
                this.state.invocation_schedules?.splice(index!, 1);
            }
        }
    }

    editInvocationSchedule(id) {
        let data = this.state.invocation_schedules?.find(p => p.tempId === id)
        this.setState({
            invocationScheduleEditedId: id,
            invocationScheduleName: data?.run_at_time
        })
    }

    updateInvocationSchedule() {
        const isValid = this.validateInvocationSchedule();
        if (isValid) {
            let index = this.state.invocation_schedules?.findIndex(p => p.tempId === this.state.invocationScheduleEditedId)
            let items = this.state.invocation_schedules;
            items![index!]['run_at_time'] = this.state.invocationScheduleName
            this.setState({
                invocation_schedules: items,
                invocationScheduleEditedId: 0,
                invocationScheduleName: ""
            }, () => {
                // this.validate();
            })
        }
    }

    //**************** CRUD of Invocation Schedules Report Health *************** *//

    createInvocationSchedulesHealth(id: number) {
        const isValid = this.validateInvocationScheduleHealth();
        if (isValid) {
            id = (this.state.invocation_schedules_health?.length ?? 0) + 1;

            // Create updated health array without mutating state directly
            const updatedHealth = [
                ...(this.state.invocation_schedules_health ?? []),
                {
                    id: 0,
                    tempId: id,
                    installed_report_application_id: this.state.id,
                    report_health: this.state.invocationScheduleHealthName
                }
            ];

            // Update the state with new values
            this.setState({
                invocation_schedules_health: updatedHealth,
                invocationScheduleHealthId: id,
                invocationScheduleHealthName: ""
            });
        }
    }

    removeInvocationScheduleHealth(id) {
        let index = this.state.invocation_schedules_health?.findIndex(p => p.tempId === id);
        if (index! > -1) {
            this.state.invocation_schedules_health?.splice(index!, 1);
            this.setState({
                invocation_schedules_health:  this.state.invocation_schedules_health,    
           });
        }
    };

    editInvocationScheduleHealth(id) {
        let data = this.state.invocation_schedules_health?.find(p => p.tempId === id);
        this.setState({
            invocationScheduleHealthEditedId: id,
            invocationScheduleHealthName: data?.report_health
        })
    };

    updateInvocationScheduleHealth() {
        const isValid = this.validateInvocationScheduleHealth();
        if (isValid) {
            let index = this.state.invocation_schedules_health?.findIndex(p => p.tempId === this.state.invocationScheduleHealthEditedId)
            let items = this.state.invocation_schedules_health;
            items![index!]['report_health'] = this.state.invocationScheduleHealthName;
            this.setState({
                invocation_schedules_health: items,
                invocationScheduleHealthEditedId: 0,
                invocationScheduleHealthName: ""
            });
        }
    };

    render() {
        return (
            <Fragment>
                <form onSubmit={this.handleSubmit}>
                <Modal.Header closeButton onClick={this.handleClose}>
                    </Modal.Header>
                    <Modal.Body>
                    <input type="hidden" value={this.state.id} />
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h5 className="installed-app-title">{this.state.isShowtext} Installed Application {this.state.name && <span> '{this.state.name}'</span>}</h5>
                        </div>
                        <Modal backdrop='static' keyboard={false} size="lg" show={this.state.showReportForm} onHide={this.handleReportClose}>
                            <ReportFormPopup  updateReport={this.updateReport}  handleClose={this.handleReportClose} isNew={this.state.isNew} id={this.state?.report_id}/>
                        </Modal>
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                                              
                                        <div className="row">
                                            <div className="col-xl-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Name<span className=" text-danger">*</span></label>
                                                    <input type="text"  ref={this.nameRef} maxLength={255} name="name" value={this.state.name} placeholder="Enter Name" onChange={this.handleChange} className={!this.state.nameError ? 'form-control' : 'form-control  is-invalid'} />
                                                    {/* <div className="hint-text">Required. Length up to 255.</div> */}
                                                    <div className="invalid-feedback">
                                                        {this.state.nameError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-4">
                                                <div className="form-group">
                                                    <label>Report<span className="text-danger">*</span></label>
                                                    <div className="input-group mb-3">
                                                        <input autoComplete="off" placeholder="Search" id="reportName" name="reportName" type="text" onChange={this.handleChange} value={this.state.reportName}
                                                            className={!this.state.reportError ? 'form-control' : 'form-control  is-invalid'} />
                                                        {this.renderReport()}                                                        
                                                        {/* <div className="input-group-append">                                                            
                                                            <button type="button"  className={`btn btn-primary ${ this.state?.report_id == 0 || null ? "disabled" : ""}`}  onClick={this.handleShowEditReport}><i className="far fa-edit"></i></button> 
                                                        </div> */}
                                                        <div className="invalid-feedback" >
                                                            {this.state.reportError}
                                                        </div>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div className="col-xl-4 col-md-4">
                                                <div className="form-group mb-2 position-relative">
                                                    <label>Application<span className=" text-danger">*</span></label>
                                                    <div className="input-group mb-3">
                                                        <input autoComplete="off" placeholder="Search" id="applicationName" name="applicationName" type="text" onChange={this.handleChange} value={this.state.applicationName}
                                                            className={!this.state.applicationError ? 'form-control' : 'form-control  is-invalid'} />
                                                        {this.renderApplication()}
                                                        {/* <div className="input-group-append">                                                            
                                                            <a href="#" className={`btn btn-success mr-1`}><i className="fas fa-plus" style={{ color: "white" }}></i></a>
                                                            <a href="#" className={`btn btn-primary ${this.state.application_id === 0 || null ? "disabled" : ""}`}  ><i className="far fa-edit"></i></a>
                                                        </div> */}
                                                        <div className="invalid-feedback">
                                                            {this.state.applicationError}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-6">
                                                <div className="form-group shadow-sm p-3 mb-4 ">
                                                   
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="enabled" checked={this.state.enabled} onChange={this.handleChange} className="custom-control-input" id="enabled" />
                                                        <label className="custom-control-label" htmlFor="enabled">Enabled</label>
                                                    </div>
                                                    <small >This instance of the app will not run under any circumstances</small>
                                                </div>
                                                <div className="form-group  shadow-sm p-3 mb-4 ">
                                                   
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="run_on_report_change" checked={this.state.run_on_report_change} onChange={this.handleChange} className="custom-control-input" id="run_on_report_change" />
                                                        <label className="custom-control-label" htmlFor="run_on_report_change">Run On Report Change</label>
                                                    </div>
                                                    <small>The app will run automatically every time the report changes.</small>
                                                </div>
                                                <div className="form-group  shadow-sm p-3 mb-0 ">
                                                    
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="billable" checked={this.state.billable} onChange={this.handleChange} className="custom-control-input" id="Billable" />
                                                        <label className="custom-control-label" htmlFor="Billable">Billable</label>
                                                    </div>
                                                    <small>If this is true, the app will billable.</small>
                                                </div>
                                            </div>
                                            <div className="col-md-6 shadow-sm ">
                                                {this.props.location?.includes("/invocation-schedule/") && <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="form-group">
                                                            <label>Invocation Schedules</label>
                                                            <div className="input-group">
                                                                <input type="text" maxLength={5} name="invocationScheduleName" placeholder="Ex.: 13:00" value={this.state.invocationScheduleName} onChange={this.handleChange} className={!this.state.invocationSchedulesError ? 'form-control' : 'form-control  is-invalid'} />
                                                                {/* <label>Optional. Length up to 255.</label> */}
                                                                <div className="input-group-append">
                                                                    {this.state.invocationScheduleEditedId === 0 && <button type="button" onClick={() => this.createInvocationschedules(this.state.invocationScheduleId!)} className="btn btn-success" disabled={this.state.invocationScheduleName === ""}><i className="fas fa-plus"></i> Add</button>}
                                                                    {this.state.invocationScheduleEditedId != 0 && <button type="button" onClick={() => this.updateInvocationSchedule()} className="btn btn-primary "><i className="far fa-save"></i> Save</button>}
                                                                </div>
                                                                <div className="invalid-feedback">
                                                                    {this.state.invocationSchedulesError}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 "><label className="mt-4">Run at Time <br /> (HH:MM)</label></div>
                                                    <div className="col-xl-12 col-md-12" >
                                                        <table className="table table-striped table-sm text-center invoSchedule-table">
                                                            <thead>
                                                                <th>Run at Time</th>
                                                                <th>Action</th>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.invocation_schedules?.map((item: InvocationSchedulesModel) => {
                                                                    return (
                                                                        <tr key={item.id}>
                                                                            <td> {item.run_at_time}</td>
                                                                            <td> <Link className="btn btn-sm btn-outline-primary far fa-pencil-alt" onClick={() => this.editInvocationSchedule(item.tempId)}></Link>&nbsp;
                                                                    {(this.state.id === 0 || item.id === 0) && <Link className="btn  btn-sm btn-outline-danger far fa-trash-alt" onClick={() => this.removeInvocationSchedule(item.tempId)}></Link>}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>}                                                
                                                 {/* Invocation Schedules Report Health */}
                                                 {this.props.location?.includes("/invocation-health-schedule/") && <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="form-group">
                                                            <label>Invocation Schedules Health</label>
                                                            <div className="input-group">
                                                                <input type="text" maxLength={5} name="invocationScheduleHealthName" placeholder="Ex.: 75" value={this.state.invocationScheduleHealthName} onChange={this.handleChange} className={!this.state.invocationSchedulesHealthError ? 'form-control' : 'form-control  is-invalid'} />
                                                                {/* <label>Optional. Length up to 100.</label> */}
                                                                <div className="input-group-append">
                                                                    {this.state.invocationScheduleHealthEditedId === 0 && <button type="button" onClick={() => this.createInvocationSchedulesHealth(this.state.invocationScheduleHealthId!)} className="btn btn-success" disabled={this.state.invocationScheduleHealthName === ""}><i className="fas fa-plus"></i> Add</button>}
                                                                    {this.state.invocationScheduleHealthEditedId != 0 && <button type="button" onClick={() => this.updateInvocationScheduleHealth()} className="btn btn-primary "><i className="far fa-save"></i> Save</button>}
                                                                </div>
                                                                <div className="invalid-feedback">
                                                                    {this.state.invocationSchedulesHealthError}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 "><label className="mt-4">Run at Health</label></div>
                                                    <div className="col-xl-12 col-md-12" >
                                                        <table className="table table-striped table-sm text-center invoSchedule-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Run at Health</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.invocation_schedules_health?.map((item: InvocationSchedulesHealthModel, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td> {item.report_health}</td>
                                                                            <td> <button type="button" className="btn btn-sm btn-outline-primary far fa-pencil-alt" onClick={() => this.editInvocationScheduleHealth(item.tempId)}></button>&nbsp;
                                                                    {(this.state.id === 0 || item.id === 0) && <button type="button" className="btn  btn-sm btn-outline-danger far fa-trash-alt" onClick={() => this.removeInvocationScheduleHealth(item.tempId)}></button>}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="row  mt-5">
                                            <div className="col-xl-6 col-lg-12 col-md-12 mb-5">
                                                <h5>Revealable Options</h5>
                                                <label className="small">Provides non-sensitive details and control options to the application (NOT ENCRYPTED)</label>
                                                <Editor StringData={this.state.revealable_options} FormateType="yaml" onChange={this.editorhandleChangeReveableOptions} />
                                                {this.state.revealableOptionsError != "" && <div style={{ color: 'red' }}>
                                                    {this.state.revealableOptionsError}
                                                </div>}
                                            </div>
                                            <div className="col-xl-6 col-lg-12 col-md-12">
                                                <h5>Options</h5>
                                                <label className="small">Provides authentication details and other control options for the application (ENCRYPTED)</label>
                                                <Editor StringData={this.state.options} FormateType="yaml" onChange={this.editorhandleChangeOptions} />
                                            </div>
                                        </div>

                                    
                            </div>
                        </div>
                        <div className="text-center mt-3 mb-4">
                        <button type="button" className="btn btn-lg btn-default" onClick={this.handleClose}>{ButtonType.Cancel}</button>
                                {!this.state.isSaving && <Fragment>
                                    <input type="submit" className="btn btn-lg btn-primary  ml-3" onClick={this.handleClick} value={ButtonType.Save} />                                    
                                </Fragment>
                                }
                                <Loader loading={this.state.isSaving} marginBottom="0px" marginTop="8px" width="368px" ></Loader>
                        </div>
                    </div>
                    </Modal.Body>
                </form>
            </Fragment>
        )
    }
}
