import { HttpWrapper, AppConfig } from '../../../../../core'
import { InvocationHealthSchedulesListResponseModel, InvocationHealthScheduleDetailModel, PostInvocationHealthSchedulesModel, EditInvocationHealthSchedulesModel } from '../models';
import { DeleteResponseModel, LinkResourcesResponseModel } from '../../../../../shared/models';
import { PostExportModel } from "../../../../../shared/export/post.export.model";
export class InvocationHealthSchedulesService {
  private wrapper: HttpWrapper;

  constructor() {
    this.wrapper = new HttpWrapper();
  }

  getInvocationSchedulesList(currentPage: number, pageSize: number, sortBy: string, sortOrder: boolean, filters: any) {
    return this.wrapper.post<InvocationHealthSchedulesListResponseModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules_by_health/get_all_invocation_schedules_by_health?current_page=' + currentPage + '&page_size=' + pageSize + '&sort_by=' + sortBy + '&is_desc=' + sortOrder, { filters: filters });
  }

  getInvocationScheduleById(id: number) {
    return this.wrapper.get<InvocationHealthScheduleDetailModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules_by_health/' + id);
  }

  postInvocationSchedule(invocation_schedule: PostInvocationHealthSchedulesModel) {
    return this.wrapper.post<PostInvocationHealthSchedulesModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules_by_health', invocation_schedule);
  }

  editInvocationSchedule(id: number) {
    return this.wrapper.get<EditInvocationHealthSchedulesModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules_by_health/edit/' + id);
  }

  updateInvocationSchedule(invocation_schedule: PostInvocationHealthSchedulesModel) {
    return this.wrapper.put<PostInvocationHealthSchedulesModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules_by_health/' + invocation_schedule.id, invocation_schedule);
  }

  getLinkedResourcesById(id: number) {
    return this.wrapper.get<LinkResourcesResponseModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules_by_health/' + id + '/linked_resources');
  }

  deleteInvocationScheduleById(id: number) {
    return this.wrapper.delete<DeleteResponseModel>(AppConfig.apiEndpoint + '/v1/invocation_schedules_by_health/' + id);
  }

  exportInvocationSchedules(exportData?: PostExportModel) {
    return this.wrapper.getFileByPost(AppConfig.apiEndpoint + '/v1/invocation_schedules_by_health/export', exportData);
  }


}