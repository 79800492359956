import React, { Component, Fragment } from 'react'
import { InstalledApplicationDeleteModel, InstalledApplicationDeleteResponseModel } from './models'
import { InstalledApplicationService } from './services/installed.application.service';
import { HttpResponse } from '../../../../core';
import { NavLink } from "react-router-dom";
import { InstalledApplicationTabs } from './InstalledApplicationTabs'
import { DropdownItemModel } from '../../../../shared/models';
import { ButtonType } from '../../../../shared/enums/button.enum';
import { ShowSuccessMessage, HandleNotFoundResponse } from '../../../../shared/helpers';
import { NotFoundResponseArea } from '../../../../shared/enums';

interface IState {
    result?: InstalledApplicationDeleteModel;
    deleteResult?: InstalledApplicationDeleteResponseModel;
    isDoubleleClick?: boolean;
}
export class InstalledApplicationDelete extends Component<any, IState> {
    private service: InstalledApplicationService;
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.service = new InstalledApplicationService();
        this.deleteRecord = this.deleteRecord.bind(this);
    }

    initialState: Partial<IState> = {
        result: {
            id: 0,
            name: "",
            control_missions: [],
            database_engines: [],
            invocation_schedules_health: [],
            invocation_schedules: [],
            scheduled_reports: []
        },
        deleteResult: { isdeleted: false },
        isDoubleleClick: false
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.service.getLinkedResourcesById(this.props.match.params.id)
            .then((res: HttpResponse<InstalledApplicationDeleteModel>) => {
                if (res && res.result) {
                    this.setState({
                        result: res.result
                    });
                }
            })
            .catch(ex => {
                HandleNotFoundResponse(ex, NotFoundResponseArea.InstalledApplications, this.props)
            });
    }

    deleteRecord() {
        if (!this.state.isDoubleleClick) {
            this.setState({ isDoubleleClick: true })
            this.service.deleteRecordById(this.props.match.params.id)
                .then((res: HttpResponse<InstalledApplicationDeleteResponseModel>) => {
                    if (res && res.result) {
                        this.setState({
                            deleteResult: res.result
                        }, () => {
                            if (this.state.deleteResult?.isdeleted) {
                                ShowSuccessMessage("Installed Application successfully deleted.");
                                this.props.history.push('/installed-report-application/list');
                            }
                        });
                    }
                });
        }
    }

    render() {
        return (
            <Fragment>
                {/* {this.state.result?.scheduled_reports.value == this.props.match.params.id && <div> */}
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h5 className="installed-app-title">Delete  Installed Application '{this.state.result?.name}'</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <InstalledApplicationTabs id={this.props.match.params.id} url="/installed-report-application/delete/" />
                                <div className="alert font-weight-bold  alert-light"> <i className="fas fa-exclamation-circle text1"></i> Are you sure you want to delete this installed report application <strong>“{this.state.result?.name}”</strong> ?</div>
                                <p className="mt-4 text-danger"><i className="fas fa-exclamation-triangle text1"></i> The following related items may be deleted or orphaned:</p>
                                <div className="row ">
                                    <div className="col-2">
                                        <div className="card card-primary shadow-sm">
                                            <div className="card-header">
                                                <h4>Installed Report Application</h4>
                                            </div>
                                            <div className="card-body">
                                                <NavLink className="badge badge-light text-link badge-link" to={"/installed-report-application/detail/" + this.state.result?.id}>{this.state.result?.name} </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.result?.scheduled_reports.length != 0 && <div className="col-2">
                                        {this.state.result?.scheduled_reports.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-info shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Scheduled Report</h4> </div>
                                                    <div className="card-body">
                                                        <NavLink className="badge badge-light text-link badge-link" to={"/installed-report-application/detail/" + item.value}>#{item.value}</NavLink></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.state.result?.database_engines.length != 0 && <div className="col-2">
                                        {this.state.result?.database_engines.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-danger shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Database Engine </h4> </div><div className="card-body">
                                                        <NavLink className="badge badge-light text-link badge-link" to={"/database-engine/detail/" + item.value}>{item.text}</NavLink></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.state.result?.invocation_schedules_health?.length != 0 && <div className="col-2">
                                        {this.state.result?.invocation_schedules_health?.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-secondary shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Invocation Health Schedules</h4> </div><div className="card-body">
                                                        <NavLink className="badge badge-light text-link badge-link" to={"/invocation-health-schedule/delete/" + item.value}>Invocation Health Schedule #{item.value}</NavLink></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.state.result?.invocation_schedules.length != 0 && <div className="col-2">
                                        {this.state.result?.invocation_schedules.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-warning shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Invocation Schedules</h4> </div><div className="card-body">
                                                        <NavLink className="badge badge-light text-link badge-link" to={"/invocation-schedule/delete/" + item.value}>Invocation Schedule #{item.value}</NavLink></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                    {this.state.result?.control_missions.length != 0 && <div className="col-2">
                                        {this.state.result?.control_missions.map((item: DropdownItemModel) => {
                                            return (
                                                <div className="card card-success shadow-sm" key={item.value}>
                                                    <div className="card-header"><h4>Control Mission </h4> </div><div className="card-body"> <span className="badge badge-light badge-link">Control Mission #{item.value}</span></div>
                                                </div>
                                            );
                                        })}
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="mx-auto">
                                <button className="btn btn-lg btn-danger" disabled={this.state.isDoubleleClick} onClick={this.deleteRecord}> {ButtonType.Delete}</button>
                                <NavLink type="button" className="btn btn-lg btn-default ml-3" to="/installed-report-application/list">{ButtonType.Cancel}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div>} */}
            </Fragment>
        )
    }
}
